import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uaParserJs from 'ua-parser-js';
import ReactJson from 'react-json-view';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search'; // Import SearchIcon
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Modal from '../../components/Modal/Modal';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import Link from '@material-ui/core/Link';
import DoneIcon from '@material-ui/icons/Done';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'createdAt',
    label: 'Created At',
  },

  {
    id: 'stickyResponse.order_id',
    label: 'Sticky Id',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'affid',
    label: 'AffId',
    align: 'right',
  },
  {
    id: 'campaignId',
    label: 'CID',
    align: 'right',
  },
  {
    id: 'last4digits',
    label: 'Last 4 digits',
    align: 'right',
  },
  {
    id: 'stickyResponse.orderTotal',
    label: 'Total',
    align: 'right',
  },

  {
    align: 'right',
    label: 'Status',
  },
  {
    align: 'right',
    label: 'Details', // Add this line
  },
  {
    align: 'right',
    label: 'Delete',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  modal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    zIndex: 1000,
    width: '80%',
    maxHeight: '80%',
    overflowY: 'auto',
  },
  modalClose: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: 100,
  },
}));

export default function OrdersTable({ orders, onDeleteOrder, ordersTotal, pagination, onPaginationChange }) {
  const parser = new uaParserJs();
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    onPaginationChange({
      ...pagination,
      page: newPage,
    });
  }

  function handleChangeRowsPerPage(event) {
    onPaginationChange({
      limit: event.target.value,
      page: 0,
    });
  }

  function handleDeleteOrder(id) {
    setDeleteModalOpen(true);
    setOrderToDelete(id);
  }

  function renderTransactionStatus(row) {
    if (row.fakeOrder) {
      return 'Fake order';
    }

    if (row.stickyResponse && row.stickyResponse.response_code === '100') {
      return (
        <Tooltip title='Successful'>
          <DoneIcon style={{ color: 'green' }} />
        </Tooltip>
      );
    }

    return (
      <>
        <Tooltip
          title={
            row.stickyResponse
              ? row.stickyResponse && row.stickyResponse.error_message
              : 'Transaction status not available.'
          }
        >
          <ClearIcon color='error' />
        </Tooltip>
      </>
    );
  }

  function renderCookie(item) {
    if (!item) {
      return '';
    }
    const values = Object.entries(item);

    return values.map(([key, value], index) => (
      <Typography key={index} variant='caption' display='block' gutterBottom>
        <b>{key}</b>: {value}
      </Typography>
    ));
  }

  function renderDeviceInfo(str) {
    parser.setUA(str);
    const result = parser.getResult();

    return (
      <Box>
        <Typography variant='caption' display='block' gutterBottom>
          {result.browser.name} {result.browser.version} | {result.os.name} {result.os.version} |{' '}
          {result.device.vendor} {result.device.model} {result.device.type}
        </Typography>
      </Box>
    );
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <React.Fragment key={row.createdAt}>
        {isModalOpen && (
          <div className={classes.modal}>
            <div className={classes.modalContent}>
              <button className={classes.modalClose}>
                <ClearIcon onClick={() => setIsModalOpen(false)} />
              </button>
              <ReactJson
                src={row}
                collapsed={false}
                iconStyle='triangle'
                indentWidth={2}
                enableClipboard={true}
                displayObjectSize={false}
                displayDataTypes={false}
                style={{
                  fontSize: '12px',
                }}
              />
            </div>
          </div>
        )}
        <TableRow>
          <TableCell>
            <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component='th'>
            <Typography variant='caption' gutterBottom style={{ whiteSpace: 'nowrap' }}>
              <span>{moment(row.createdAt).format('lll')}</span>
            </Typography>
          </TableCell>
          <TableCell>
            {row.stickyResponse && row.stickyResponse.order_id && (
              <Link
                target='_blank'
                href={`https://manna.sticky.io/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&act=&sequence=1&show_by_id=${row.stickyResponse.order_id}`}
              >
                {row.stickyResponse.order_id}
              </Link>
            )}
          </TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell align='right'>{row.affid}</TableCell>
          <TableCell align='right'>{row.campaignId}</TableCell>
          <TableCell align='right'>{row?.cookie?.last4digits || row.creditCardType}</TableCell>
          <TableCell align='right'>
            {row.stickyResponse && row.stickyResponse.orderTotal && `$${row.stickyResponse.orderTotal}`}
          </TableCell>
          <TableCell align='right'>{renderTransactionStatus(row)}</TableCell>
          <TableCell align='right'>
            <IconButton color='primary' aria-label='View order' onClick={() => setIsModalOpen(true)}>
              <SearchIcon />
            </IconButton>
          </TableCell>
          <TableCell align='right'>
            <IconButton
              color='secondary'
              aria-label='Delete order'
              component='span'
              onClick={() => handleDeleteOrder(row._id)}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box margin={1}>
                <Typography variant='h6' gutterBottom component='div'>
                  Order details
                </Typography>
                <Table size='small' aria-label='purchases'>
                  <TableBody>
                    <TableRow>
                      <TableCell>Products:</TableCell>
                      <TableCell>
                        {row.offers.map((item, index) => (
                          <div key={index}>
                            {item.quantity} {item.name} | {item.billing_model_name}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Order source URL:</TableCell>
                      <TableCell
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {row.notes}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>User cookies:</TableCell>
                      <TableCell
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {renderCookie(row.cookie)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Device info:</TableCell>
                      <TableCell
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        {renderDeviceInfo(row.userAgent)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div className={classes.root}>
      <Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box>
          <Typography variant='h6' gutterBottom>
            Are you sure you want to delete this order?
          </Typography>
        </Box>
        <Box className={classes.modalButtons}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginRight: '10px' }}
            onClick={async () => {
              setDeleteModalOpen(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={async () => {
              await onDeleteOrder(orderToDelete);
              setDeleteModalOpen(false);
            }}
          >
            Yes
          </Button>
        </Box>
      </Modal>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby='tableTitle' aria-label='enhanced table'>
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={ordersTotal}
            />
            <TableBody>
              {stableSort(orders, getComparator(order, orderBy)).map((row, index) => (
                <Row key={row.createdAt} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          count={ordersTotal}
          rowsPerPageOptions={[25, 50, 100, 500, 1000]}
          rowsPerPage={pagination.limit}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
